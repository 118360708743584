import React from 'react';
import './Home.css';
import logo from './logo.jpg'

function Home() {
  return (
    <main className="mp3-page" style={{ maxWidth: '100%', height: '100rem' }} >
     <img src={logo} alt="ACFT Elite Logo" style={{ margin: '20px 0', maxWidth: '300px', height: 'auto' }} />
    </main>
  );
}

export default Home;