import React from 'react';
import './PrivacyPolicy.css';
import { Container, Paper, Typography, List, ListItem } from '@mui/material';

function PrivacyPolicy() {
    return (
        <main className="mp3-page" style={{ backgroundColor: '#212a20', color: 'white', minHeight: '100vh' }}>
            <section className="top-section">
                <Container maxWidth="md" style={{ marginTop: '20px' }}>
                    <Paper style={{ padding: '30px', backgroundColor: '#2a3126', color: 'white' }}>
                        <Typography variant="h4" gutterBottom>
                            Privacy Policy for ACFT Elite
                        </Typography>
                        <Typography variant="subtitle1" gutterBottom>
                            Effective Date: 11/15/2024
                        </Typography>
                        <Typography variant="subtitle1" gutterBottom>
                            Last Updated: 11/15/2024
                        </Typography>
                        <Typography variant="body1" paragraph>
                            This Privacy Policy (“Policy”) governs the collection, use, and disclosure of personal information by ACFT Elite (“we,” “us,” or “our”) in connection with your use of the ACFT Elite mobile application (the “App”). By accessing or using the App, you acknowledge that you have read, understood, and agreed to the terms set forth in this Policy. If you do not agree with this Policy, please refrain from using the App.
                        </Typography>

                        <Typography variant="h6" gutterBottom>
                            1. Information We Collect
                        </Typography>
                        <Typography variant="body1" paragraph>
                            ACFT Elite collects the following categories of information:
                        </Typography>
                        <List>
                            <ListItem><Typography variant="body1">- Required Personal Information:</Typography></ListItem>
                            <ListItem><Typography variant="body1">  - Full name</Typography></ListItem>
                            <ListItem><Typography variant="body1">  - Email address</Typography></ListItem>
                            <ListItem><Typography variant="body1">  - Sex</Typography></ListItem>
                            <ListItem><Typography variant="body1">- Optional Information (User-Provided):</Typography></ListItem>
                            <ListItem><Typography variant="body1">  - Exercise results (e.g., performance metrics for specific exercises)</Typography></ListItem>
                            <ListItem><Typography variant="body1">  - Height</Typography></ListItem>
                            <ListItem><Typography variant="body1">  - Weight</Typography></ListItem>
                            <ListItem><Typography variant="body1">  - Waist circumference</Typography></ListItem>
                            <ListItem><Typography variant="body1">  - Neck circumference</Typography></ListItem>
                        </List>
                        <Typography variant="body1" paragraph>
                            This information is collected directly from you when you input data into the App.
                        </Typography>

                        <Typography variant="h6" gutterBottom>
                            2. Purpose of Data Collection
                        </Typography>
                        <Typography variant="body1" paragraph>
                            We process your personal information for legitimate business purposes, including but not limited to:
                        </Typography>
                        <List>
                            <ListItem><Typography variant="body1">- Provision of Services: To operate, maintain, and enhance the App’s functionality and user experience.</Typography></ListItem>
                            <ListItem><Typography variant="body1">- Performance Analytics: To track and analyze exercise and body composition data for your personal fitness goals.</Typography></ListItem>
                            <ListItem><Typography variant="body1">- Communication: To send administrative updates, respond to inquiries, or provide technical support.</Typography></ListItem>
                            <ListItem><Typography variant="body1">- Compliance and Legal Obligations: To comply with applicable laws, regulations, or legal processes.</Typography></ListItem>
                        </List>

                        <Typography variant="h6" gutterBottom>
                            3. Legal Basis for Data Processing
                        </Typography>
                        <Typography variant="body1" paragraph>
                            We collect and process personal information based on one or more of the following legal grounds:
                        </Typography>
                        <List>
                            <ListItem><Typography variant="body1">- Consent: By providing personal information, you consent to its processing in accordance with this Policy.</Typography></ListItem>
                            <ListItem><Typography variant="body1">- Contractual Necessity: Processing may be required to fulfill the services provided through the App.</Typography></ListItem>
                            <ListItem><Typography variant="body1">- Legitimate Interests: To improve App functionality and user experience, consistent with your rights and expectations.</Typography></ListItem>
                        </List>
                        <Typography variant="h6" gutterBottom>
                            4. Data Sharing and Disclosure
                        </Typography>
                        <Typography variant="body1" paragraph>
                            ACFT Elite does not sell, lease, or trade your personal information. However, your data may be shared under the following limited circumstances:
                        </Typography>
                        <List>
                            <ListItem><Typography variant="body1">- Authorized Service Providers: We may engage third-party vendors or contractors to support App operations, subject to strict confidentiality obligations.</Typography></ListItem>
                            <ListItem><Typography variant="body1">- Legal Compliance: Personal information may be disclosed to comply with legal obligations, enforce terms of use, or protect our rights.</Typography></ListItem>
                            <ListItem><Typography variant="body1">- Aggregate Data: We may share anonymized, aggregated data that does not identify you, for research or analytics purposes.</Typography></ListItem>
                        </List>

                        <Typography variant="h6" gutterBottom>
                            5. Data Retention and Security
                        </Typography>
                        <Typography variant="body1" paragraph>
                            We retain personal information only as long as necessary to fulfill the purposes outlined in this Policy, unless a longer retention period is required by law. To protect your information, we implement robust administrative, technical, and physical safeguards. Despite our efforts, no method of electronic storage or transmission is entirely secure, and we cannot guarantee absolute security.
                        </Typography>

                        <Typography variant="h6" gutterBottom>
                            6. Your Rights
                        </Typography>
                        <Typography variant="body1" paragraph>
                            You have certain rights with respect to your personal information, including but not limited to:
                        </Typography>
                        <List>
                            <ListItem><Typography variant="body1">- Access and Portability: The right to request access to your data and obtain a copy.</Typography></ListItem>
                            <ListItem><Typography variant="body1">- Correction: The right to request correction of inaccurate or incomplete information.</Typography></ListItem>
                            <ListItem><Typography variant="body1">- Erasure: The right to request deletion of your personal data, subject to legal or contractual obligations.</Typography></ListItem>
                            <ListItem><Typography variant="body1">- Data Restriction: The right to limit processing of your data under certain conditions.</Typography></ListItem>
                        </List>
                        <Typography variant="body1" paragraph>
                            To exercise these rights, contact us at info@acftelite.com.
                        </Typography>

                        <Typography variant="h6" gutterBottom>
                            7. International Data Transfers
                        </Typography>
                        <Typography variant="body1" paragraph>
                            If you access the App outside the United States, please note that your data may be transferred to and processed in the United States. By using the App, you consent to such transfers.
                        </Typography>

                        <Typography variant="h6" gutterBottom>
                            8. Children’s Privacy
                        </Typography>
                        <Typography variant="body1" paragraph>
                            The App is not intended for individuals under the age of 13, and we do not knowingly collect information from children. If we discover that a minor’s data has been collected without parental consent, we will promptly delete it.
                        </Typography>

                        <Typography variant="h6" gutterBottom>
                            9. Changes to This Privacy Policy
                        </Typography>
                        <Typography variant="body1" paragraph>
                            We reserve the right to amend this Policy at our sole discretion. Any material changes will be communicated through the App or other appropriate channels. Your continued use of the App after such changes constitutes your acceptance of the updated Policy.
                        </Typography>

                        <Typography variant="h6" gutterBottom>
                            10. Contact Information
                        </Typography>
                        <Typography variant="body1" paragraph>
                            If you have questions, concerns, or requests regarding this Policy, please contact us at: info@acftelite.com
                        </Typography>
                        <Typography variant="body1" paragraph>
                            This Policy is governed by the laws of the State of Pennsylvania, without regard to its conflict of law principles.
                        </Typography>
                        <Typography variant="caption" display="block" gutterBottom>
                            © 2024 ACFT Elite. All Rights Reserved.
                        </Typography>
                    </Paper>
                </Container>
                <div className='spacer'></div>
            </section>
        </main>
    );
}

export default PrivacyPolicy;

