import logo from './logo.svg';
import './App.css';
import React from 'react';
import Home from './Pages/Home/Home';
import PrivacyPolicy from './Pages/PrivacyPolicy/PrivacyPolicy';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

function App() {
  return (
    <Routes>
      <Route path="/" element={<Home/>} />
      <Route path="/privacy" element={<PrivacyPolicy/>} />
    </Routes>
  );
}

export default App;
